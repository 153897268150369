import { RoutePaths } from '../../RoutePaths';
import { getHrefWithSameQueryParams } from '../../utilities';
import { Link } from '../../components/Link';
import { useState } from 'react';

const testimonials = [
  {
    text: `I love zCloud's concept of simplifying infrastructure while ensuring cost control. It's simple yet powerful, with excellent online support.`,
    author: 'Gabriel H.',
    company: 'Zap Notes',
    image:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/testimonial-gabriel-h-zap-notes.png',
    style: 'outline',
    hide: true,
  },
  {
    text: `Delegated full technical migration to zCloud team, and they handled it masterfully. Database, security, everything taken care of. Impressive!`,
    author: 'Reynardt P.',
    company: 'Bemarke',
    image:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/testimonial-reynardt-p-bemarke.png',
    style: 'outline',
    hide: true,
  },
  {
    text: `zCloud support is phenomenal, fast and straight to the point, the tools to build and deploy are very easy and fast to use.`,
    author: 'André P.',
    company: 'Bytetalk',
    image:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/testimonial-andre-p-bytetalk.png',
    style: 'outline',
    hide: true,
  },
];
const publishedTestimonials = testimonials.filter(({ hide }) => !hide);

// Use this video and cover photo for test
// https://videos.ctfassets.net/xny2w179f4ki/3qp0qLfCQJWYrL4jN44VVm/ece9ffe7b105f763a9b5d12ab16f603c/Homepage_hero_video.mp4
// https://cdn.sanity.io/images/y0yxkfgr/production/03257441c6481b7c83d1de1b482dd191133331bd-3456x1932.png?w=3840&q=75&fit=clip&auto=format
const videoCaseStudies = [
  {
    id: 'caught-video',
    company: 'Caught',
    text: '"We save 75 to 80% of our server running costs, and on top of that, receive better service whenever we have a question."',
    author: 'Mark Naus',
    position: 'Founder',
    logo: 'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-caught-logo.png',
    photo:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-caught-photo.jpeg',
    ytVideo: 'LWJxaPjZzpY',
    videoThumb:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-caught-video-thumb.png',
    route: RoutePaths.CS_CAUGHT,
    isPortuguese: false,
    anchor: '#caught-video',
  },
  {
    id: 'poli-video',
    company: 'Poli',
    text: '"Migrated from AWS and others, now many environments have all services are under the same roof with high-level support! zCloud works brilliantly!"',
    author: 'Gabriel Henrique',
    position: 'Co-founder',
    logo: 'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-poli-logo.png',
    photo:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-poli-photo.png',
    ytVideo: '4U3XUWWVS-Q',
    videoThumb:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-poli-video-thumb.jpg',
    route: RoutePaths.CS_POLI,
    isPortuguese: true,
    anchor: '#poli-video',
  },
];

const caseStudies = [
  {
    company: 'ALLOHOUSTON',
    text: `Custom Cloud Solution for Paris 2024 Olympic Games: "Amazing technical support and the reactivity is incredible. I highly recommend working with them!"`,
    author: 'Victor Parpoil',
    position: 'Co-founder',
    logo: 'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-allohouston-logo.png',
    logoClassName: 'w-16',
    photo:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-allohouston-photo.webp',
    photoClassName: 'w-12 rounded-full',
    route: RoutePaths.CS_ALLOHOUSTON,
  },
  {
    company: 'QuickCoach',
    text: `"Working with zCloud was a game-changer for QuickCoach! Hosting costs reduced by 10x, app performance boosted by 3x. Users are thrilled!."`,
    author: 'Reynaldo Reyna',
    position: 'Lead Architect',
    logo: 'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-quickcoach-logo.png',
    photo:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-quickcoach-photo.png',
    route: RoutePaths.CS_QUICKCOACH,
  },
  {
    company: 'Dunderon',
    text: `"Congratulations! zCloud works brilliantly! Migrated from AWS and others, now all services are under one roof with top-notch support! Thanks guys!"`,
    author: 'Robert Kviberg',
    position: 'Founder',
    logo: 'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-dunderon-logo.png',
    photo:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-dunderon-photo.png',
    route: RoutePaths.CS_DUNDERON,
  },
  {
    company: 'EatFreshTech',
    text: `"zCloud is a dream PaaS with an amazing dashboard, eliminating hours of manual work. They helped me migrate seamlessly. Highly recommend!"`,
    author: 'Matt Durr',
    position: 'Founder',
    logo: 'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-eatfresh-logo.png',
    photo:
      'https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-eatfresh-photo.png',
    route: RoutePaths.CS_EATFRESH,
  },
];
const CaseStudiesSection = ({ page }) => {
  const [playingVideo, setPlayingVideo] = useState(null);

  return (
    <>
      <ul className="mb-8 grid grid-cols-1 gap-8">
        {videoCaseStudies.map(
          ({
            id,
            text,
            author,
            position,
            logo,
            photo,
            ytVideo,
            videoThumb,
            route,
            company,
            isPortuguese,
          }) => (
            <li
              className="rounded-8 grid scroll-mt-32 grid-cols-1 gap-7 bg-neutral-500 px-6 py-6 lg:grid-cols-[1fr_2fr]"
              id={id}
              key={id}
            >
              <div className="flex flex-col items-start justify-center gap-6">
                {isPortuguese && (
                  <p className="text-p flex items-center gap-2">
                    <img
                      className=""
                      src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/br-flag.png"
                      alt="BR"
                    />
                    <span className="text-small">Conteúdo em Português</span>
                  </p>
                )}
                <div className="w-48">
                  <img src={logo} alt={company} />
                </div>
                <h6 className="text-h6 text-light-500 font-light">{text}</h6>
                <div className="flex items-center gap-2">
                  <div className="w-12">
                    <img src={photo} alt={author} className="rounded-full" />
                  </div>
                  <p className="flex flex-col">
                    <small className="text-small text-light-700">
                      {author}
                    </small>
                    <small className="text-small text-light-800">
                      {position}
                    </small>
                  </p>
                </div>
                {route && (
                  <Link
                    event={`website_${page}_readMore${company}_click`}
                    to={getHrefWithSameQueryParams(route)}
                    className="text-p text-lime-600"
                  >
                    <span>Read more</span> <i className="fa fa-arrow-right"></i>
                  </Link>
                )}
              </div>
              <div className="order-first lg:order-none">
                <div
                  className={`relative aspect-video cursor-pointer ${
                    playingVideo === ytVideo ? 'hidden' : ''
                  }`}
                  onClick={() => setPlayingVideo(ytVideo)}
                >
                  <img
                    src={videoThumb}
                    alt=""
                    className="rounded-8 h-full w-full object-cover"
                  />
                  <i className="fas fa-play-circle absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-7xl text-white hover:text-lime-600" />
                </div>

                <iframe
                  className={`rounded-8 aspect-video w-full ${
                    playingVideo === ytVideo ? '' : 'hidden'
                  }`}
                  src={`https://www.youtube.com/embed/${ytVideo}${
                    playingVideo === ytVideo ? '?autoplay=1' : ''
                  }`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </li>
          )
        )}
      </ul>
      <ul className="grid grid-cols-1 gap-8 lg:grid-cols-3">
        {caseStudies.map(
          ({ text, author, position, logo, photo, route, company, logoClassName, photoClassName }) => (
            <li className="rounded-8 flex flex-col items-start gap-6 bg-neutral-500 px-6 py-6">
              <img src={logo} alt={company} className={logoClassName} />
              <h6 className="text-h6 text-light-500 font-light">{text}</h6>
              <div className="flex items-center gap-2">
                <img src={photo} alt={author} className={photoClassName} />
                <p className="flex flex-col">
                  <small className="text-small text-light-700">{author}</small>
                  <small className="text-small text-light-800">
                    {position}
                  </small>
                </p>
              </div>
              <Link
                event={`website_${page}_readMore${company}_click`}
                to={getHrefWithSameQueryParams(route)}
                className="text-p text-lime-600"
              >
                <span>Read more</span> <i className="fa fa-arrow-right"></i>
              </Link>
            </li>
          )
        )}
      </ul>

      {!!publishedTestimonials.length && (
        <>
          <h4 className="text-h4 text-light-500 pb-4 pt-16">
            What our clients are saying
          </h4>
          <ul className="-mx-4 flex flex-wrap justify-center">
            {publishedTestimonials.map(
              ({ text, author, image, company, style }) => {
                let wrapperClass;
                // Only 2 styles now, but we will add more later.
                switch (style) {
                  case 'outline':
                    wrapperClass = 'border border-neutral-300';
                    break;
                  default:
                    wrapperClass = 'bg-neutral-500';
                    break;
                }

                return (
                  <li className="p-4 lg:shrink-0 lg:grow-0 lg:basis-1/2">
                    <div
                      className={`flex flex-col gap-2 rounded-lg px-6 py-4 ${wrapperClass}`}
                    >
                      <p className="text-p text-light-500">"{text}" </p>
                      <div className="text-p text-light-700 flex gap-2">
                        <img
                          className="h-[24px] w-[24px] rounded-full object-cover grayscale"
                          src={image}
                          alt={author}
                        />
                        <span>
                          {author} ({company})
                        </span>
                      </div>
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        </>
      )}
    </>
  );
};

export default CaseStudiesSection;

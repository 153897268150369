module.exports.RoutePaths = {
  HOME: '/',
  CASE_STUDIES: '/case-studies',
  CS_POLI: '/case-studies/poli',
  CS_CAUGHT: '/case-studies/caught',
  CS_ALLOHOUSTON: '/case-studies/allohouston',
  CS_DUNDERON: '/case-studies/dunderon',
  CS_QUICKCOACH: '/case-studies/quickcoach',
  CS_EATFRESH: '/case-studies/eatfresh',
  WORKSHOP: '/workshop',
  REGION_IN_BRAZIL: '/regiao-brasil',
  BRING_YOUR_OWN_PROVIDER: '/bring-your-own-provider',
  ENTERPRISE: '/enterprise',
  LOAD_TESTING: '/load-testing',
  BOOK_DEMO: '/book-demo',
  AFFILIATES: '/affiliates',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  METEOR: '/host-your-meteor-apps-on-zcloud',
};

module.exports.Sitemap = {
  HOME: { changefreq: 'daily', priority: '1.0' },
};
